$(window).load(function(){

  function addErrorClasses() {
    $('.section-contractor_products .field_with_errors').addClass('has-error');
  }
  addErrorClasses();

  function addDefaultQuantity(selector) {
    quantityField = $(selector).data('productQuantity');
    $('#contractor_product_quantity').val(quantityField).change();;
  }

  $('#contractor_product_definition_components_options_product_id').parent().on( 'change', 'select', function(){
    $('#contractor_product_extra_id').val('');
    addDefaultQuantity('#contractor_product_definition_components_options_product_id :selected');
  });

  $('#contractor_product_extra_id').parent().on( 'change', 'select', function(){
    $('#contractor_product_definition_components_options_product_id').val('');
    addDefaultQuantity('#contractor_product_extra_id :selected');
  });

  $('.section-contractor_products select').each(function() {
    $(this).prop("disabled", $(this).find('option[value!=""]').length == 0);
  });

  $('#contractor_product_quantity').on( 'input, change', function() {
    $('.actions input[type="submit"]').prop('disabled', $('#contractor_product_quantity').val() == "");
  });

  if($('#contractor_product_quantity').val() == "") {
    $('.actions input[type="submit"]').prop('disabled', true);
  }

});
