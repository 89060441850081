$(document).ready(function(){
  if( $('#property_form #property_property_type').val() == 'home' ) {
    $('#property_form .property_number').hide();
  } else {
    $('#property_form .portal').hide();
    $('#property_form .letter').hide();
  }

  if( $('#property_form #property_property_type').val() == 'garage' ) {
    $('#property_form .typology_id').hide();
  }

  function toggleSelectTypologyType() {
    var type = ($('#property_form #property_property_type').val());
    $("#property_form .typology_id select#property_typology_id option[data-typology-type='" + type + "']:first").prop("selected", true);
  }
  toggleSelectTypologyType();

  function toggleDisabledTypologyType() {
    $("#property_form .typology_id select#property_typology_id option").removeAttr("disabled");
    var type = ($('#property_form #property_property_type').val());
    $("#property_form .typology_id select#property_typology_id option[data-typology-type]:not([data-typology-type*='" + type + "'])").attr("disabled", "true");
  }
  toggleDisabledTypologyType();

  $('#property_form #property_property_type').on('change', function() {
    if( $('#property_form #property_property_type').val() != 'garage' ) {
      $('#property_form .typology_id').show(200);
    } else {
      $('#property_form #property_typology_id').val('');
      $('#property_form .typology_id').hide(200);
    }
    if( $('#property_form #property_property_type').val() == 'home' ) {
      $('#property_form #property_number').val('');
      $('#property_form .property_number').hide(200);

      $('#property_form .portal').show(200);
      $('#property_form .letter').show(200);
    } else {
      
      $('#property_form #property_portal').val('');
      $('#property_form #property_letter').val('');
      $('#property_form .portal').hide(200);
      $('#property_form .letter').hide(200);

      $('#property_form .property_number').show(200);
    }

    toggleDisabledTypologyType();
    toggleSelectTypologyType();
  });
});

