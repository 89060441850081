$(document).on('keyup', ".bootstrap-duallistbox-container .filter", function () {
  $(this).blur();
  $(this).focus();
});

$(function(){
  var option_products = $('#option_product_ids').bootstrapDualListbox({
    nonSelectedListLabel: 'No seleccionados',
    selectedListLabel: 'Seleccionados',
    infoText: 'Mostrando los {0} productos',
    infoTextEmpty: 'Lista vacía',
    infoTextFiltered: '<span class="label label-warning">Filtrados</span> {0} de {1}',
    preserveSelectionOnMove: 'moved',
    selectorMinimalHeight: 250,
    moveOnSelect: true
    // nonSelectedFilter: 'ion ([7-9]|[1][0-2])'
  });

  if ($('select#option_product_ids').length > 0) {
    var optionID  = $('select#option_product_ids').data('optionid');

    if (optionID) {
      getSelected();
    } else {
      getAll(); 
    }
  }

  function getSelected() {
    var selectedOptions;
    var allOptions;
    var selectedGroup = $('.products-filter #option_selected_groups').find(':selected').data('groupId');

    $.getJSON(`/options/${optionID}/options_products.json`,
    {
      methods: {
        selected_group: selectedGroup? selectedGroup : ''
      }
    },
    function(data) {
      selectedOptions = _.map(data.selectedItems, (productItem) => {
        return `<option selected value="${productItem.id}" data-id="${productItem.id}" data-name="${productItem.name}">` +
        `${productItem.name}</option>`;
      }).join();

      nonSelectedOptions = _.map(data.allExceptSelectedItems, (productItem) => {
        return `<option value="${productItem.id}" data-id="${productItem.id}" data-name="${productItem.name}">` +
        `${productItem.name}</option>`;
      }).join();

      var allOptions = selectedOptions+nonSelectedOptions;

      if (allOptions) {
        $('.bootstrap-duallistbox-container select').first().empty();
        option_products.find('*').not('[data-sortindex]').remove();

        option_products.append(allOptions);
        option_products.bootstrapDualListbox('refresh', true);
      }
    });
  }

  function getAll() {
    var selectedOptions;
    var allOptions;
    var selectedGroup = $('.products-filter #option_selected_groups').find(':selected').data('groupId');

    $.getJSON(`/products.json`,
    {
      methods: {
        selected_group: selectedGroup? selectedGroup : ''
      }
    },
    function(data) {
      allOptions = _.map(data.selectedProducts, (productItem) => {
        return `<option value="${productItem.id}" data-id="${productItem.id}" data-name="${productItem.name}">` +
        `${productItem.name}</option>`;
      }).join();

      if (allOptions) {
        $('.bootstrap-duallistbox-container select').first().empty();
        option_products.find('*').not('[data-sortindex]').remove();

        option_products.append(allOptions);
        option_products.bootstrapDualListbox('refresh');
      }
    });
  }

  $(document).on('change', '.section-options .products-filter #option_selected_groups', function(e) {
    if (!optionID) {
      getAll();
    } else {
      getSelected();
    }
  });
});
