$(document).ready(function(){

  const emptyOption = '<option value="">Seleccionar</option>';
  const selector = '#childrenSelect .products';

  const getSelect = ($container, field) => {
    return $($container).find(`[data-type="${field}"]`);
  };

  const getText = ($container, field) => {
    return $(`[data-type="${field}"] :selected`, $container).text()
  };

  const getVal = ($container, field) => {
    return $(`[data-type="${field}"]`, $container).val();
  };

  const getParent = ($container, field) => {
    return $(`[data-type="${field}"]`, $container).data('selected');
  };

  const fields = ['parent', 'name', 'external_color', 'internal_color', 'size', 'model'];

  const childrenFields = (field) => {
    return fields.slice(_.indexOf(fields, field) + 1);
  };

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  const disableIfReadonly = () => {
    $('.listproducts select').attr('disabled', true);
  };

  if ($('#childrenSelect .listproducts .nested-fields').length > 0) {
    if (typeof ($('#childrenSelect .listproducts .products select :selected').data('isCompound')) === 'undefined') {
      $('#childrenSelect .listproducts .products .child').toggle(false);
    } else {
      $('#childrenSelect .listproducts .products .child').toggle($('#childrenSelect .listproducts .products select :selected').data('isCompound'));
    }
    if ($('#definition_components_option_option_types').data('option-type') === 'unique') {
      $('.links .add_fields').addClass('disabled');
    }
  };

  $('#childrenSelect').on('cocoon:after-insert', function(e) {
    if (typeof ($(e.target.previousSibling).find('.product select :selected').data('isCompound')) === 'undefined') {
      $(e.target.previousSibling).find('.products .child').toggle(false);
    }
    if ($('#childrenSelect .listproducts .nested-fields').length > 0){

      if ($('#definition_components_option_option_types').data('option-type') === 'unique') {
        $('.links .add_fields').addClass('disabled');
      }
    }
    $(e.target.previousSibling).find('.quantity input').val('1');
  });

  $('#childrenSelect').on('cocoon:after-remove', function(e) {
    let parentDiv = $(e.target).closest('.nested-fields');
    parentDiv.next('input').remove();
    parentDiv.remove();
    if ($('#definition_components_option_option_types').data('option-type') === 'unique') {
      if ($('#childrenSelect .listproducts .nested-fields').length === 0){
        $('.links .add_fields').removeClass('disabled');
      } else {
        $('.links .add_fields').addClass('disabled');
      }
    }
  });

  $(document).on('change', '#childrenSelect .listproducts .nested-fields .product', function(e) {
    if ($(e.target).find(':selected').data('isCompound')) {
      $(e.target).closest('.products').find('.child').show("slow");
    } else {
      $(e.target).closest('.products').find('.child').hide("slow");
    }

    if ($(e.target).find(':selected').data('quantityType') === "meters") {
      $(e.target).closest('.products').find('.quantity').hide("slow");
      $(e.target).closest('.products').find('.quantity input').val('')
      $(e.target).closest('.products').find('.observations').removeClass('col-md-5');
      $(e.target).closest('.products').find('.observations').addClass('col-md-10');
    } else {
      $(e.target).closest('.products').find('.quantity').show("slow");
      $(e.target).closest('.products').find('.observations').removeClass('col-md-10');
      $(e.target).closest('.products').find('.observations').addClass('col-md-5');
    }
  });

  $(document).on('change', `${selector} select`, function(e) {
    let type = $(this).data('type');
    let $container = $(this).closest(selector)
    let options = '';
    let $select;
    let availableSelect = '';
    let unavailableSelects = '';
    let preferred;

    let parentID = getVal($container, 'parent');
    let nameID   = getText($container, 'name');
    let externalColorID = getText($container, 'external_color');
    let internalColorID = getText($container, 'internal_color');
    let sizeID = getText($container, 'size');

    let nameSelected = getVal($container, 'name');
    let externalColorSelected = getVal($container, 'external_color');
    let internalColorSelected = getVal($container, 'internal_color');
    let sizeSelected = getVal($container, 'size');

    if (!parentID) {
      _.each(childrenFields(type), (field) => {
        getSelect($container, field).empty().append(emptyOption);
      });
      return;
    }

    switch(type) {

      case 'parent':
        if ($(e.target).find(':selected').data('isCompound') === false){
          return;
        }

        if (!parentID) {
          _.each(childrenFields(type), (field) => {
            getSelect($container, field).empty().append(emptyOption).prop('disabled', true);
          });
          return;
        }

        availableSelect = '';
        unavailableSelects = '';

        $.getJSON(`/products/${parentID}.json`,
        {
          methods: {
            selected_parent: parentID
          }
        }, function(data) {
          selectOptions = _.map(data.selectedProducts, (productItem) => {
            let { parent, name, external_color, internal_color, size, model } = productItem;
            let found = [name, external_color, internal_color, size, model].find(element => element !== (null || ""));

            availableSelect = getKeyByValue(productItem, found);
            unavailableSelects = ['name', 'external_color', 'internal_color', 'size', 'model'].filter(function(item) { return item !== availableSelect; });

            return `<option value="${productItem.name}" data-name="${productItem.name}">` +
            `${productItem.name}</option>`;
          }).join();

          if (!selectOptions) {
            unavailableSelects = ['name', 'external_color', 'internal_color', 'size', 'model'];
            _.each(unavailableSelects, (unavailableSelect) => {
              getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
            });
            return;
          }

          let uniqOptions = [...new Set(selectOptions.split(','))];

          $select = getSelect($container, availableSelect);
          $select.empty().append(emptyOption + uniqOptions).prop('disabled', false);

          _.each(unavailableSelects, (unavailableSelect) => {
            getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
          });

          preferred = $select.data('preferred');

          if (preferred && ($(`option[value="${preferred}"]`, $select).length != 0)) {
            $select.val(preferred);
            $select.trigger('change');
          }
        });
      break;

      case 'name':
        if (!nameSelected) {
          _.each(childrenFields(type), (field) => {
            getSelect($container, field).empty().append(emptyOption).prop('disabled', true);
          });
          return;
        }

        availableSelect = '';
        unavailableSelects = '';

        $.getJSON(`/products/${parentID}.json`,
        {
          methods: {
            selected_parent: parentID,
            selected_name: nameID
          }
        },
        function(data) {
          selectOptions = _.map(data.selectedProducts, (productItem) => {
            let { external_color, internal_color, size, model } = productItem;
            let found = [external_color, internal_color, size, model].find(element => element !== (null || ""));

            availableSelect = getKeyByValue(productItem, found);
            unavailableSelects = ['external_color', 'internal_color', 'size', 'model'].filter(function(item) { return item !== availableSelect; });

            return `<option value="${productItem[`${availableSelect}`]}" data-name="${productItem[`${availableSelect}`]}">` +
            `${productItem[`${availableSelect}`]}</option>`;
          }).join();

          let uniqOptions = [...new Set(selectOptions.split(','))];

          $select = getSelect($container, availableSelect);
          $select.empty().append(emptyOption + uniqOptions).prop('disabled', false);

          _.each(unavailableSelects, (unavailableSelect) => {
            getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
          });

          preferred = $select.data('preferred');

          if (preferred && ($(`option[value="${preferred}"]`, $select).length != 0)) {
            $select.val(preferred);
            $select.trigger('change');
          }
        });
      break;

      case 'external_color':
        if (!externalColorSelected) {
          _.each(childrenFields(type), (field) => {
            getSelect($container, field).empty().append(emptyOption).prop('disabled', true);
          });
          return;
        }

        availableSelect = '';
        unavailableSelects = '';

        $.getJSON(`/products/${parentID}.json`,
        {
          methods: {
            selected_parent: parentID,
            selected_name: nameID,
            selected_external_color: externalColorID
          }
        },
        function(data) {
          selectOptions = _.map(data.selectedProducts, (productItem) => {
            let { internal_color, size, model } = productItem;
            let found = [internal_color, size, model].find(element => element !== (null || ""));

            availableSelect = getKeyByValue(productItem, found);
            unavailableSelects = ['internal_color', 'size', 'model'].filter(function(item) { return item !== availableSelect; });

            return `<option value="${productItem[`${availableSelect}`]}" data-name="${productItem[`${availableSelect}`]}">` +
            `${productItem[`${availableSelect}`]}</option>`;

          }).join();

          let uniqOptions = [...new Set(selectOptions.split(','))];

          $select = getSelect($container, availableSelect);
          $select.empty().append(emptyOption + uniqOptions).prop('disabled', false);

          _.each(unavailableSelects, (unavailableSelect) => {
            getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
          });

          preferred = $select.data('preferred');

          if (preferred && ($(`option[value="${preferred}"]`, $select).length != 0)) {
            $select.val(preferred);
            $select.trigger('change');
          }
        });
      break;

      case 'internal_color':
        if (!internalColorSelected) {
          _.each(childrenFields(type), (field) => {
            getSelect($container, field).empty().append(emptyOption).prop('disabled', true);
          });
          return;
        }

        availableSelect = '';
        unavailableSelects = '';

        $.getJSON(`/products/${parentID}.json`,
        {
          methods: {
            selected_parent: parentID,
            selected_name: nameID,
            selected_external_color: externalColorID,
            selected_internal_color: internalColorID
          }
        },
        function(data) {
          selectOptions = _.map(data.selectedProducts, (productItem) => {
            let { size, model } = productItem;
            let found = [size, model].find(element => element !== (null || ""));

            availableSelect = getKeyByValue(productItem, found);
            unavailableSelects = ['size', 'model'].filter(function(item) { return item !== availableSelect; });

            return `<option value="${productItem[`${availableSelect}`]}" data-name="${productItem[`${availableSelect}`]}">` +
            `${productItem[`${availableSelect}`]}</option>`;
          }).join();

          let uniqOptions = [...new Set(selectOptions.split(','))];

          $select = getSelect($container, availableSelect);
          $select.empty().append(emptyOption + uniqOptions).prop('disabled', false);

          _.each(unavailableSelects, (unavailableSelect) => {
            getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
          });

          preferred = $select.data('preferred');

          if (preferred && ($(`option[value="${preferred}"]`, $select).length != 0)) {
            $select.val(preferred);
            $select.trigger('change');
          }
        });
      break;

      case 'size':
        if (!sizeSelected) {
          _.each(childrenFields(type), (field) => {
            getSelect($container, field).empty().append(emptyOption).prop('disabled', true);
          });
          return;
        }

        availableSelect = '';
        unavailableSelects = '';

        $.getJSON(`/products/${parentID}.json`,
        {
          methods: {
            selected_parent: parentID,
            selected_name: nameID,
            selected_external_color: externalColorID,
            selected_internal_color: internalColorID,
            selected_size: sizeID
          }
        },
        function(data) {
          selectOptions = _.map(data.selectedProducts, (productItem) => {
            let { model } = productItem;
            let found = [model].find(element => element !== (null || ""));

            availableSelect = getKeyByValue(productItem, found);
            unavailableSelects = ['model'].filter(function(item) { return item !== availableSelect; });

            return `<option value="${productItem[`${availableSelect}`]}" data-name="${productItem[`${availableSelect}`]}">` +
            `${productItem[`${availableSelect}`]}</option>`;
          }).join();

          let uniqOptions = [...new Set(selectOptions.split(','))];

          $select = getSelect($container, availableSelect);
          $select.empty().append(emptyOption + uniqOptions).prop('disabled', false);

          _.each(unavailableSelects, (unavailableSelect) => {
            getSelect($container, unavailableSelect).empty().append(emptyOption).prop('disabled', true);
          });

          preferred = $select.data('preferred');

          if (preferred && ($(`option[value="${preferred}"]`, $select).length != 0)) {
            $select.val(preferred);
            $select.trigger('change');
          }
        });
      break;
    }
    if ($('.listproducts .readonly').length > 0) {
      disableIfReadonly();
    }
  });

  _.each($('#childrenSelect .listproducts .nested-fields .product select'), (select) => {

    const selector = '#childrenSelect .products';
    var unavailableSelects = ['name', 'external_color', 'internal_color', 'size', 'model'];
    var $container = select.closest(selector);

    _.each(unavailableSelects, (unavailableSelect) => {
      getSelect($container, unavailableSelect).prop('disabled', true);
    });

    preferred = $(select).data('preferred');

    if (preferred && ($(`option[value="${preferred}"]`, $(select)).length != 0)) {
      $(select).val(preferred);
      $(select).trigger('change');
    }
  });
});
