$(document).ready(function() {

  var pickcalendar = '';

  var add_datepicker_to_input = function() {
    $(this).parent().addClass('input-group date');
    $(this).parent().removeClass('form-group');

    var label = $(this).parent().find('label');
    $(label).insertBefore($(label).parent());

    $(this).after('<a style="" class="input-button input-group-addon" data-toggle> <i class="glyphicon glyphicon-calendar"></i> </a>');

    pickcalendar = $(this).flatpickr({
      locale: "es",
      altInput: true,
      altFormat: "j F Y",
      dateFormat: "d/m/Y"
    });
  };

  $(document).on('click','.input-group.date a.input-group-addon',function(e) {
    setTimeout(function(){
      pickcalendar.toggle();
    }, 0);
  });

  $('.form-group .flatpickr').each(add_datepicker_to_input);
});
