$(document).ready(function(){

  function toggleFixedVariableForms() {
    if ($('#options_product_is_fixed_price').is(":checked")) {
      $('#variablePriceForm .links a.add_fields').addClass('disabled');
      $('#variablePriceForm .well.nested-fields .delete-action input:not(:first)').val('1');

      $('#variablePriceForm .container-fluid:not(:first)').hide(400);
      $('#variablePriceForm .well.nested-fields .typology select').val('');
      $('#variablePriceForm .well.nested-fields .typology').hide(400);
    } else {
      $('#variablePriceForm .links a.add_fields').removeClass('disabled');
      $('#variablePriceForm .well.nested-fields .typology').show(400);
    }
  }

  toggleFixedVariableForms();

  $('#options_product_is_fixed_price').on('click', function (e) {
    toggleFixedVariableForms();
  });
});
