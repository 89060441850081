/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import 'jquery';
window.$ = window.jQuery = require('jquery');

import 'bootstrap-sass';
import 'metismenu/dist/metisMenu';
import 'stylesheets/application';
import 'lodash';

import 'bootstrap-table';

require('@kollegorna/cocoon-vanilla-js');
require('@rails/ujs').start();

require('bootstrap-multiselect/dist/js/bootstrap-multiselect.js');
require('./bootstrap-multiselect/custom_multiselect');

require('./bootstrap-duallistbox/jquery.bootstrap-duallistbox');
require('./bootstrap-duallistbox/custom-duallist');
require('./floating-scroll/jquery.floatingscroll.min.js');
require('./floating-scroll/custom-floatingscroll.js');

import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

require('./components/components_projects_typologies');
require('./definition_components/definition_components');
require('./definition_components_options_product/product_selector');
require('./properties/components_projects_property_form');
require('./properties/definition_components_form');
require('./properties/property_form');
require('./products/product_form');
require('./option_products/options_products');
require('./contractor_products/contractor_products.js');
require('./reports/block_report.js');

require('./common/remember_tab');
require('./common/custom_datepicker');

$(document).ready(function(){
  $('#menu').metisMenu();
});
