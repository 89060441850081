$(document).ready(function(){

  if ($('#childrenForm').length > 0) {
    $('#childrenForm').toggle($('#product_is_compound')[0].checked);
  }
  
  $('#product_is_compound').change(function() {
    if (this.checked) {
      $('#childrenForm').show(400);
    } else {
      $('#childrenForm').hide(400);
    }
  });
});
