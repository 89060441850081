$(document).ready(function(){

  if ($('select#components_projects_property_components_project_id').length > 0) {
    toggleQuantityInput();
  }

  $('.section-components_projects_properties').on( 'change', 'select', function(){
    toggleQuantityInput();
  });

  function toggleQuantityInput() {
    let selection = $('#components_projects_property_components_project_id');
    let quantityInput = selection.closest('.row').find('.quantity');

    if (selection.find(":selected").data('componentType') === "variable") {
      quantityInput.prop('disabled', false);
    } else {
      quantityInput.val('').prop('disabled', true);
    }
  }

});