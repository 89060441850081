$(document).ready(function(){

  var selection = $('.components_projects_typologies select');
  var quantityFixedFields = selection.find(":selected[data-component-type='fixed']").closest('.row').find('.quantity');
  var quantityVariableFields = selection.find(":selected[data-component-type='variable']").closest('.row').find('.quantity');

  quantityFixedFields.prop('disabled', true);

  $('#components_projects_typologies').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    selection = $('.components_projects_typologies select');
    quantityFixedFields = selection.find(":selected[data-component-type='fixed']").closest('.row').find('.quantity');
    quantityVariableFields = selection.find(":selected[data-component-type='variable']").closest('.row').find('.quantity');
    quantityFixedFields.prop('disabled', true);
    quantityVariableFields.prop('disabled', false);
  });

  $('#components_projects_typologies').on( 'change', 'select', function(){
    selection = $('.components_projects_typologies select');
    quantityFixedFields = selection.find(":selected[data-component-type='fixed']").closest('.row').find('.quantity');
    quantityVariableFields = selection.find(":selected[data-component-type='variable']").closest('.row').find('.quantity');
    quantityFixedFields.val('').prop('disabled', true);
    quantityVariableFields.prop('disabled', false);
  });

});
