$(document).ready(function(){

  const defaultOptions = {
    nonSelectedText: 'Ninguno',
    nSelectedText:   'seleccionados',
    allSelectedText: 'Todos',
    selectAllText: ' Seleccionar todos',
    includeSelectAllOption: true
  };

  $('.multiselect-selects .form-group select[multiple]').each(function() {
    const config = Object.assign(
      {},
      defaultOptions,
      $(this).data('multiselect-config')
      );
    $(this).multiselect(config);
  });
});
