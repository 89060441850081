$(document).ready(function(){

  let selection = $('.new_definition_component select :selected');
  let textSelection = $('.new_definition_component select :selected').text();
  let nameField = $('.new_definition_component .name input');

  if (selection.data("componentType") === "fixed") {
    nameField.text(textSelection).val(textSelection).prop('readonly', true);
  } else {
    nameField.text(textSelection).val(textSelection).prop('readonly', false);
  }

  $('#new_definition_component').on('change', 'select', function(){
    let selection = $('.new_definition_component select :selected');
    let textSelection = $('.new_definition_component select :selected').text();
    let nameField = $('.new_definition_component .name input');

    if (selection.data("componentType") === "fixed") {
      nameField.text(textSelection).val(textSelection).prop('readonly', true);
    } else {
      nameField.text(textSelection).val(textSelection).prop('readonly', false);
    }
  }); 
});
